<template>
	<div class="map">
		<l-map ref="map" :zoom="zoom" :center="[49.2265592, 16.5957531]" :options="mapOptions" @ready="addControls">
			<l-control-layers position="topright"></l-control-layers>
			<l-tile-layer
				v-for="tileProvider in baseLayers"
				:key="tileProvider.name"
				:name="tileProvider.name"
				:visible="tileProvider.visible"
				:url="tileProvider.url"
				:attribution="tileProvider.attribution"
				:maxZoom="tileProvider.maxZoom"
				layer-type="base"
			></l-tile-layer>
			<l-wms-tile-layer
				v-for="layer in wmsBaseLayers"
				:key="layer.name"
				:url="layer.url"
				:layers="layer.layers"
				:visible="layer.visible"
				:name="layer.name"
				:transparent="false"
				layer-type="base"
				format="image/png"
				:maxZoom="layer.maxZoom"
			/>
			<l-wms-tile-layer
				v-for="layer in wmsOverlayLayers"
				:key="layer.name"
				:url="layer.url"
				:layers="layer.layers"
				:visible="layer.visible"
				:name="layer.name"
				:transparent="true"
				:opacity="layer.opacity"
				layer-type="overlay"
				format="image/png"
				:maxZoom="layer.maxZoom"
			/>
			<l-control-scale position="bottomleft" :imperial="false" :metric="true"></l-control-scale>
			<l-geo-json
				:geojson="geojson"
				:options="options"
			/>
		</l-map>
	</div>
  <div id="sidebar">
    <MainMenu></MainMenu>
  </div>
  <div id="avatar">
    <VuetifyAvatar></VuetifyAvatar>
  </div>
  <div id="credits">
    <ProjectCredits></ProjectCredits>
  </div>
  <add-shelter-dialog ref="addShelter" />
  <delete-shelter-dialog ref="deleteShelterDialog" />
  <div ref="tooltip" id="tooltip" style="display: none">
    {{ $t("shelter.shelter") }} {{tooltipData.id}}: {{tooltipData.title}}
  </div>
  <VueUnityComponent ref="virtualTour" />
</template>

<script>
import "leaflet/dist/leaflet.css";
import { LMap, LTileLayer, LGeoJson, LControlLayers, LControlScale, LWmsTileLayer } from "@vue-leaflet/vue-leaflet";

import 'leaflet-contextmenu';
import 'leaflet-contextmenu/dist/leaflet.contextmenu.min.css';

import * as L from 'leaflet'
import "leaflet-sidebar/src/L.Control.Sidebar.css"
import "@/components/sidebar.css"
import 'leaflet-sidebar';

import "leaflet-easybutton/src/easy-button.css"
import 'leaflet-easybutton'

import 'leaflet-geosearch/dist/geosearch.css'
import { CivilDefenseMapProvider, GeoSearchControl } from 'leaflet-geosearch';
//import { OpenStreetMapProvider, GeoSearchControl } from 'leaflet-geosearch';

// civil defence shelter
import VuetifyAvatar from "@/components/VuetifyAvatar.vue";
import "@/leaflet-mypanel.js"
import MainMenu from "@/components/MainMenu.vue";
import VueUnityComponent from "@/components/VueUnityComponent.vue";
import ProjectCredits from "@/components/ProjectCredits.vue";
import {useShelterStore} from "@/stores/shelterStore";
import addShelterDialog from "@/components/addShelterDialog.vue";
import DeleteShelterDialog from "@/components/DeleteShelterDialog.vue";

import baseLayers from '@/components/baseLayers.json'
import wmsBaseLayers from '@/components/wmsBaseLayers.json'
import wmsOverlayLayers from '@/components/wmsOverlayLayers.json'

import 'leaflet.locatecontrol'
import 'leaflet.locatecontrol/dist/L.Control.Locate.css'
import 'leaflet.browser.print/dist/leaflet.browser.print'

export default {
	components: {
    ProjectCredits,
    MainMenu,
	VueUnityComponent,
    VuetifyAvatar,
		LMap,
		LTileLayer,
		LGeoJson,
		LControlLayers,
		LControlScale,
		"l-wms-tile-layer": LWmsTileLayer,
    addShelterDialog,
    DeleteShelterDialog,
	},
	data() {
		return {
      dialog: {
        show: false,
        text: null,
      },
      geojson: null,
			geosearchLocation: null,
      tooltipData: {
        title: null,
        id: null
      },
			zoom: 13,
			baseLayers: baseLayers.baseLayers,
			wmsBaseLayers: wmsBaseLayers.wmsBaseLayers,
			wmsOverlayLayers: wmsOverlayLayers.wmsOverlayLayers,
			mapOptions: {
				contextmenu: true,
				contextmenuWidth: 200,
				contextmenuItems: [
					{
						text: 'Přidat kryt podle adresy..',
						callback: async () => {
							console.log(this.$data.geosearchLocation)
							this.$data.geosearchLocation.lng = this.$data.geosearchLocation.x
							this.$data.geosearchLocation.lat = this.$data.geosearchLocation.y
							await this.$refs.addShelter.showDialog(this.$data.geosearchLocation)
							await this.$refs.addShelter.showDialog(this.$data.geosearchLocation)
							this.$refs.addShelter.$forceUpdate()
							await this.$refs.addShelter.showDialog(this.$data.geosearchLocation)
						},
					},
					{
						text: this.$t('shelter.newShelter'),
						callback: (e) => {
							this.$refs.addShelter.showDialog(e.latlng)
						},
					},
					{
						separator: true,
						index: 1
					},
					{
						text: "Google Street View",
						callback: (e) => {
							window.open('https://www.google.com/maps?layer=c&cbll='+e.latlng.lat+','+e.latlng.lng, 'GSV')
						}
					}
				]
			},
		};
	},
	computed: {
		options() {
			return {
				onEachFeature: this.onEachFeatureFunction
			};
		},
		onEachFeatureFunction() {
			return (feature, layer) => {
				layer.bindTooltip(feature.properties.name,
        layer.bindPopup(() => {
          this.$data.tooltipData = {
            id: feature.id,
            title: feature.properties.name
          }
					this.$refs.tooltip.style.display="block"
          return this.$refs.tooltip
        }),
        {
          permanent: false,
          sticky: true
        });
        layer.bindContextMenu({
          contextmenu: true,
          contextmenuInheritItems: false,
          contextmenuItems: [{
            text: this.$t('shelter.edit'),
            callback: () => {
							this.$refs.addShelter.showDialog({
								lng: feature.geometry.coordinates[0],
								lat: feature.geometry.coordinates[1],
								title: feature.properties.name,
								id: feature.id
							})
            }
          },
          {
			text: 'Virtuální prohlídka',
			callback: () => {
				this.$refs.virtualTour.showDialog()
			}
          },
          {
            text: this.$t('shelter.delete'),
            callback: () => {
              this.$refs.deleteShelterDialog.showDialog(feature.id, feature.properties.name)
            }
          },
					{
						text: "Google Street View",
						callback: () => {
							window.open('https://www.google.com/maps?layer=c&cbll='+feature.geometry.coordinates[1]+','+feature.geometry.coordinates[0], 'GSV')
						}
					}]
        });
			};
		},
	},
  methods: {
    addControls() {
      this.$nextTick(async() => {
        this.map = this.$refs.map.leafletObject

        let sidebar = L.control.sidebar('sidebar', {
          position: 'left',
          autoPan: false,
          closeButton: false
        })

        sidebar.addTo(this.map)



        // https://www.npmjs.com/package/leaflet-easybutton
        L.easyButton({
          states: [{
            stateName: 'show-sidebar',
            icon: '&equiv;',
            title: this.$t('menu.showMenu'),
            onClick: function (control) {
              //sidebar.show()
              control.state('hide-sidebar');
            }
          }, {
            icon: '&equiv;',
            stateName: 'hide-sidebar',
            onClick: function (control) {
              //sidebar.hide()
              control.state('show-sidebar');
            },
            title: this.$t('menu.hideMenu')
          }]
        }).addTo(this.map);

				// https://stackoverflow.com/questions/67780684/leaflet-geosearch-binding-results-marker-to-an-existing-one
        const provider = new CivilDefenseMapProvider();
				//const provider = new OpenStreetMapProvider();
        const search = new GeoSearchControl({
          provider: provider,
					showMarker: true,
					searchLabel: 'Zadejte adresu'
        });

				this.map.on('geosearch/showlocation', (data) => {
					this.$data.geosearchLocation = data.location
				});

				this.map.on('geosearch/marker/dragend', () => {
					alert('marker dragged')
				});


				this.map.addControl(search);
				L.control.locate({
							position: "topleft",
							strings: {
								title: this.$t('geolocation.showMe'),

							}
						})
						.addTo(this.map);
				L.control.browserPrint({
					position: "topleft",
					printModes:	[
						"Portrait",
						"Landscape",
						"Auto"
					]}
				).addTo(this.map);

        L.control.mypanel("avatar", {
          position: 'topright'
        }).addTo(this.map);
        L.control.mypanel("credits", {
          position: 'bottomright'}
        ).addTo(this.map);
      })
    }
  },
  async mounted() {
    let shelterStore = useShelterStore()

    shelterStore.$subscribe(() => {
      this.$data.geojson = shelterStore.sheltersGeoJSON
    })

    await shelterStore.getUserShelters()
  },
};

</script>

<style>
.map {
	height: 100%;
	width: 100%;
}

#tooltip {
  width: 200px;
}

</style>
