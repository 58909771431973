import axios from "axios";
import { defineStore } from "pinia"

export const useShelterStore = defineStore('shelter', {
    state: () => ({
        shelters: null,
        buildingTypes: null,
        buildingSubTypes: null,
        materialTypes: null,
        materialSubTypes: null
    }),
    actions: {
        async getUserShelters() {
            await axios.get('/user_shelters').then(async (response) => {
                    if (response.status == 200) {
                        this.$state.shelters = response.data
                    }
                }
            ).catch(async (error) => {
                console.log(error.toJSON())
            })
        },
        async createShelter(shelter){
            await axios.post('/create_shelter', shelter)
            await this.getUserShelters()
        },
        async updateShelter(shelter, shelter_id){
            await axios.patch(`/shelter/${shelter_id}`, shelter)
            await this.getUserShelters()
        },
        async deleteShelter(shelter_id){
            await axios.delete(`/shelter/${shelter_id}`)
            await this.getUserShelters()
        },
        async initBuildingProperties(){
            await this.getBuildingTypes()
            await this.getBuildingSubTypes()
            await this.getMaterialTypes()
            await this.getMaterialSubTypes()
        },
        async getBuildingTypes(){
            await axios.get('/buildingtypes').then(async (response) => {
                    if(response.status == 200) {
                        this.$state.buildingTypes = response.data
                    }
                }
            ).catch(async (error) => {
                console.log(error.toJSON())
            })
        },
        async getBuildingSubTypes(){
            await axios.get('/buildingsubtypes').then(async (response) => {
                    if(response.status == 200) {
                        this.$state.buildingSubTypes = response.data
                    }
                }
            ).catch(async (error) => {
                console.log(error.toJSON())
            })
        },
        async getMaterialTypes(){
            await axios.get('/materialtypes').then(async (response) => {
                    if(response.status == 200) {
                        this.$state.materialTypes = response.data
                    }
                }
            ).catch(async (error) => {
                console.log(error.toJSON())
            })
        },
        async getMaterialSubTypes(){
            await axios.get('/materialsubtypes').then(async (response) => {
                    if(response.status == 200) {
                        this.$state.materialSubTypes = response.data
                    }
                }
            ).catch(async (error) => {
                console.log(error.toJSON())
            })
        }
    },
    getters: {
        stateShelters: state => state.shelters,
        buildingType: state => {
            let result = new Array()
            if(state.buildingTypes) {
                state.buildingTypes.forEach((buildingType) => {
                    result.push({
                        'label': buildingType.caption,
                        'id': buildingType.id,
                    })
                })
                return result
            }
            return null
        },
        sheltersGeoJSON: state => {
            let geojson = {
                type: "FeatureCollection",
                features: new Array()
            }
            if(state.shelters) {
                state.shelters.forEach((shelter) => {
                    geojson.features.push({
                        type: "Feature",
                        properties: {
                            "@id": shelter.id,
                            name: shelter.address
                        },
                        geometry: {
                            type: "Point",
                            coordinates: [
                                shelter.y,
                                shelter.x
                            ]
                        },
                        "id": shelter.id
                    })
                })
            }
            return geojson
        }
    }
})