<template>
	<v-dialog v-model="dialog" persistent scrollable="True" max-width="600px" min-width="360px">
		<v-card>
			<v-tabs
					v-model="tab"
					bg-color="deep-purple-accent-4"
					centered
					stacked
					fixed-tabs
			>
				<v-tab value="tab-1" style="overflow-y: auto;">
					<v-icon>mdi-account</v-icon>
					{{$t('login.headline')}}
				</v-tab>

				<v-tab value="tab-2" style="overflow-y: auto;">
					<v-icon>mdi-account-outline</v-icon>
					{{$t('login.register')}}
				</v-tab>
			</v-tabs>

			<v-window v-model="tab" style="overflow-y: auto;">
				<v-window-item id="1" value="tab-1">
					<v-card>
						<v-card-text>
							<v-alert v-if="errorMessage" id="alert" type="warning" v-text="errorMessage.detail" /><br />
							<v-form  @submit.prevent="login" ref="loginForm" v-model="valid" lazy-validation>
								<v-row>
									<v-col cols="12">
										<v-text-field v-model="loginEmail" :rules="loginEmailRules" name="email" label="E-mail" required></v-text-field>
									</v-col>
									<v-col cols="12">
										<v-text-field v-model="loginPassword" :append-icon="show1? 'mdi-eye':'mdi-eye-off'" :rules="[rules.required, rules.min]" :type="show1 ? 'text' : 'password'" name="password" :label="$t('login.password')" hint="At least 8 characters" counter @click:append="show1 = !show1"></v-text-field>
									</v-col>
									<v-col class="d-flex" cols="12" sm="6" xsm="12">
									</v-col>
									<v-spacer></v-spacer>
									<v-col class="d-flex" cols="12" sm="3" xsm="12" align-end>
										<v-btn x-large block :disabled="!valid" color="success" @click="validate" type="submit"> {{$t('login.logIn')}} </v-btn>
									</v-col>
								</v-row>
							</v-form>
						</v-card-text>
					</v-card>
				</v-window-item>
				<v-window-item id="2" value="tab-2">
					<v-card>
						<v-card-text>
							<v-alert v-if="registerErrorMessage" id="alert" type="warning" v-text="registerErrorMessage.detail" /><br />
							<v-form ref="registerForm" v-model="valid" lazy-validation>
								<v-row>
									<v-col cols="12" sm="6" md="6">
										<v-text-field v-model="firstName" :rules="[rules.required]" :label="$t('login.firstname')" maxlength="20" required></v-text-field>
									</v-col>
									<v-col cols="12" sm="6" md="6">
										<v-text-field v-model="lastName" :rules="[rules.required]" :label="$t('login.lastname')" maxlength="20" required></v-text-field>
									</v-col>
									<v-col cols="12">
										<v-text-field v-model="email" :rules="emailRules" label="E-mail" required></v-text-field>
									</v-col>
									<v-col cols="12">
										<v-text-field v-model="password" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :rules="[rules.required, rules.min]" :type="show1 ? 'text' : 'password'" name="password" :label="$t('login.password')" hint="At least 8 characters" counter @click:append="show1 = !show1"></v-text-field>
									</v-col>
									<v-col cols="12">
										<v-text-field block v-model="verify" :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :rules="[rules.required, passwordMatch]" :type="show1 ? 'text' : 'password'" name="input-10-1" :label="$t('login.confirm')" counter @click:append="show1 = !show1"></v-text-field>
									</v-col>
									<v-col  cols="12" sm="6" md="6">
										<v-text-field v-model="inputValue" :rules="captchaRules"  :label="$t('login.captcha')" required></v-text-field>
									</v-col>
									<v-col cols="12" sm="6" md="6">
										<VueClientRecaptcha
												:value="inputValue"
												@getCode="getCaptchaCode"
												@isValid="checkValidCaptcha"
										/>
									</v-col>
									<v-spacer></v-spacer>
									<v-col class="d-flex ml-auto" cols="12" sm="3" xsm="12">
										<v-btn x-large block :disabled="!(valid && isValid)" color="success" @click="registerUser">
											{{ $t('login.regIn') }}</v-btn>
									</v-col>
								</v-row>
							</v-form>
						</v-card-text>
					</v-card>
				</v-window-item>
			</v-window>
		</v-card>
	</v-dialog>
</template>

<script>
// https://www.codeply.com/p/YTg6nsGf3i
import {useAuthStore} from "@/stores/authStore";
//import {useRecaptchaProvider} from "vue-recaptcha";
import VueClientRecaptcha from 'vue-client-recaptcha'
import '@/vue-client-recaptcha.css'


export default {
	name: "RegistrationForm",
	components: {
		VueClientRecaptcha,
	},
	setup() {
		// useRecaptchaProvider()
		/* pass value to captcha  */
	},
	computed: {
		passwordMatch() {
			return () => this.password === this.verify || "Password must match";
		},
		errorMessage() {
			return useAuthStore().errorMessage
		},
		registerErrorMessage() {
			return useAuthStore().registerErrorMessage
		},
	},
	methods: {
		getCaptchaCode(value) {
			/* you can access captcha code */
			this.$data.captchaCode = value
		},
		checkValidCaptcha(value) {
			/* expected return boolean if your value and captcha code are same return True otherwise return False */

			this.$data.isValid = value

		},
		validate() {
			if (this.$refs.loginForm.validate()) {
				// submit form to server/API here...
			}
		},
		reset() {
			this.$refs.form.reset();
		},
		resetValidation() {
			this.$refs.form.resetValidation();
		},
		async login() {
			const User = new FormData();
			User.append('username', this.loginEmail);
			User.append('password', this.loginPassword);
			await useAuthStore().logIn(User)


			if(await useAuthStore().isAuthenticated()) {
				this.$router.push('/');
			}
		},
		async registerUser() {
			let d = {
				'email': this.$data.email,
				'first_name': this.$data.firstName,
				'password': this.$data.password,
				'last_name': this.$data.lastName
			}
			console.log(d)
			await useAuthStore().register(d)
			if(await useAuthStore().isAuthenticated()) {
				this.$router.push('/');
			}
		}
	},

	data: vm => ({
		captchaCode: null,
		isValid: false,
		inputValue: null,
		dialog: true,
		tab: 'Model',
		tabs: [
			{name:"Login", icon:"mdi-account"},
			{name:"Register", icon:"mdi-account-outline"}
		],
		valid: true,

		firstName: "",
		lastName: "",
		email: "",
		password: "",
		verify: "",
		loginPassword: "",
		loginEmail: "",
		loginEmailRules: [
			v => !!v || "Required",
			v => /.+@.+\..+/.test(v) || "E-mail must be valid"
		],
		emailRules: [
			v => !!v || "Required",
			v => /.+@.+\..+/.test(v) || "E-mail must be valid"
		],
		captchaRules: [
			v => !!v || "Required",
			() => vm.isValid || "Captcha code is not valid"
		],
		show1: false,
		rules: {
			required: value => !!value || "Required.",
			min: v => (v && v.length >= 8) || "Min 8 characters"
		}
	})
}
</script>

