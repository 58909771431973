<template>
    <div class="text-center">
      <v-dialog v-model="show" width="800" persistent>
        <v-card>
        <div style="width: 800px; height: 600px; border: none">
          <UnityVue :unity="unityContext" tabindex="0" />
        </div>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue-darken-1"
                @click="show = false"
            >{{ "Zavřít" }}
            </v-btn>
        </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
</template>


<script>
import UnityWebgl from 'unity-webgl';
import UnityVue from 'unity-webgl/vue';

export default {
    name: "unityVue",
    components: {
        UnityVue
    },
    data () {
      return {
        show: false
      }
    },
    setup() {
      const unityContext = new UnityWebgl({
        loaderUrl:
          'https://civildefense.fit.vutbr.cz/unity/Build/Build.loader.js',
        dataUrl:
          'https://civildefense.fit.vutbr.cz/unity/Build/Build.data',
        frameworkUrl:
          'https://civildefense.fit.vutbr.cz/unity/Build/Build.framework.js',
        codeUrl:
          'https://civildefense.fit.vutbr.cz/unity/Build/Build.wasm',
      });
      return {
        unityContext
      }
    },
    methods: {
        showDialog() {
            this.$data.show = true;
        }
    }
}
</script>