import {createRouter, createWebHashHistory} from "vue-router";

import MapView from "@/views/MapView.vue";
// import LoginView from "@/views/LoginView.vue";
import RegisterView from "@/views/RegisterView.vue";

const routes = [
    {
        path: '/',
        name: 'Map',
        component: MapView,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: RegisterView
    },
    {
        path: '/register',
        name: 'Register',
        component: RegisterView
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router