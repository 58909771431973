<template>
  <v-container
      fluid
      style="height: 300px"
  >
    <v-row justify="center">
      <v-menu
          min-width="200px"
          rounded
      >
        <template v-slot:activator="{ props }">
          <v-btn
              icon
              v-bind="props"
          >
            <v-avatar
                color="brown"
                size="large"
            >
              <span class="text-h5">{{ initials }}</span>
            </v-avatar>
          </v-btn>
        </template>
        <v-card>
          <v-card-text>
            <div class="mx-auto text-center">
              <v-avatar
                  color="brown"
              >
                <span class="text-h5">{{ initials }}</span>
              </v-avatar>
              <h3>{{ fullName }}</h3>
              <p class="text-caption mt-1">
                {{ email }}
              </p>
              <v-divider class="my-3"></v-divider>
              <v-btn
                  rounded
                  variant="text"
                  @click="buttonLogout"
              >
								{{ $t("user.signOut") }}
							</v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-menu>
    </v-row>
  </v-container>
</template>

<script>

import {useAuthStore} from "@/stores/authStore";

export default {
  name: 'VuetifyAvatar',
  data() {
    return {
    }
  },
	computed: {
		initials() {
			return useAuthStore().$state.user.first_name[0] + useAuthStore().$state.user.last_name[0]
		},
		fullName() {
			return useAuthStore().$state.user.first_name + ' ' + useAuthStore().$state.user.last_name
		},
		email() {
			return useAuthStore().$state.user.email
		}

	},
  methods: {
    buttonLogout() {
      const authStore = useAuthStore()
      authStore.logOut()
      this.$router.push('/login')
    }
  }
}
</script>
